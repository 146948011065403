<script setup>
// layout -> bắt buộc
import Layout from "@/layouts/main.vue";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref, watch } from "vue";
import MethodService from "@/service/MethodService";
import LangService from "@/service/LanguageService";
import DataForm from "./dataFormGuide";
import { langWeb } from "@/stores/lang";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import toastr from "toastr";
// const source1 = "/hdsd-test.pdf";
// let checkFile = ref(false);
// let idFile = ref("");
// let urlFile = ref("");
const router = useRouter();
const route = useRoute();
// const fileHuongDan = reactive({ value: {} });
const rolesUser = ref([]);
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const loading = ref(false);
const dataTables = reactive({ value: [] });
const getListHDSD = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  let query = {
    filters: tableRules.filters,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router.replace({
    name: "HuongDanSuDung",
    query: query,
  });

  try {
    const response = await mushroom.user_manual.listAsync(dataFilter);

    if (response.result) {
      dataTables.value = [...response.result];
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};

const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "HuongDanSuDung",
    query: query,
  });
};

const watchGuide = (id) => {
  router.push({
    name: "XemHuongDanSuDung",
    params: {
      id: id,
    },
  });
};

const fn_handle = (type, scope, row) => {
  if (type == "update") {
    router.push({
      name: "CapNhatHuongDanSuDung",
      params: { id: row.id },
    });
  } else {
    Swal.fire({
      title: LangService[lang.value].t_warning,
      text: LangService[lang.value].t_data_had_delete,
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: LangService[lang.value].swal_accept,
      cancelButtonText: LangService[lang.value].swal_cancel,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.user_manual.deleteAsync({
            id: row.id, // required
          });
          if (response.result) {
            toastr.success(LangService[lang.value].toastr_delete_success);
            getListHDSD();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e);
        }
      }
    });
  }
};

const downloadFile = (valueFile) => {
  mushroom.$file.download(valueFile);
};

const addGuide = () => {
  router.push({
    name: "ThemHuongDanSuDung",
  });
};

const fn_TimKiem = () => {
  if (
    tableRules.dataSearch.value.title ||
    tableRules.dataSearch.value.title.trim() == ""
  ) {
    tableRules.dataSearch.value.title =
      tableRules.dataSearch.value.title.trim();
    tableRules.filters = MethodService.filterTable(
      JSON.stringify(tableRules.dataSearch)
    );
  }
  tableRules.offset = 0;
  tableRules.sort = "";
  getListHDSD();
};

const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  getListHDSD();
};

const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};

const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};

const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};

const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  getListHDSD();
};
const fn_tableSortChange = async (column) => {
  MethodService.tableSortChange(column, tableRules);
  loading.value = true;
  await getListHDSD();
};

const fn_hashUrl = () => {
  MethodService.hashUrl(route.query, tableRules);
};

watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
  }
);
onMounted(async () => {
  const response = JSON.parse(localStorage.getItem("userInfo"));
  rolesUser.value = response.result.roles;
  fn_hashUrl();
  await getListHDSD();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 100vh">
      <div class="card-header justify-content-between" style="display: flex">
        <h3 class="text-uppercase mb-0">{{ $t("t-guide") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border mr-2"
              @click="fn_showFormTimKiem"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="addGuide"
              v-if="
                rolesUser.includes('Admin') ||
                rolesUser.includes('AdminPrimary')
              "
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-if="tableRules.showFormSearch"
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  lable-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-guide-title')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['title']"
                          :placeholder="$t('t-placeholder-guide-title')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-guide-description')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['description']"
                          :placeholder="$t('t-placeholder-guide-description')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @currentChange="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
              background
              layout="total,sizes,prev,pager,next,jumper"
            />
          </div>
          <el-table
            size="small"
            style="width: 100%"
            max-height="500"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            :data="dataTables.value"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              props: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop="index"
              :label="$t('t-stt')"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="title"
              :label="$t('t-guide-title')"
              width="400"
              align="left"
              sortable="custom"
            >
              <template #default="scope">
                <el-tooltip
                  class="box-item"
                  effect="light"
                  placement="top"
                  :content="scope.row.title"
                >
                  <div class="guideTitle">
                    {{ scope.row.title }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="description"
              :label="$t('t-guide-description')"
              min-width="350"
              align="left"
            >
              <template #default="scope">
                <el-tooltip
                  class="box-item"
                  effect="light"
                  placement="top"
                  :content="scope.row.description"
                >
                  <div class="guideTitle">
                    {{ scope.row.description }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column :label="$t('t-action')" align="center" width="150">
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('t-see-guide')"
                    placement="top"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-warning waves-effect waves-light btn-sm"
                      :key="scope.row.guideName"
                      @click="watchGuide(scope.row.id, scope.row.urlFile)"
                    >
                      <i class="ri-eye-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('t-edit')"
                    placement="top"
                  >
                    <button
                      v-if="
                        rolesUser.includes('Admin') ||
                        rolesUser.includes('AdminPrimary')
                      "
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('t-delete')"
                    placement="top"
                  >
                    <button
                      v-if="
                        rolesUser.includes('Admin') ||
                        rolesUser.includes('AdminPrimary')
                      "
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('t-download')"
                    placement="top"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-secondary waves-effect waves-light btn-sm"
                      @click="downloadFile(scope.row.file_id)"
                    >
                      <i class="ri-download-cloud-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @currentChange="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
              background
              layout="total,sizes,prev,pager,next,jumper"
            />
          </div>
        </div>
        <!-- <iframe :src="urlFile" style="width: 100%; height: 90vh"></iframe> -->
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./guide.scss" />
